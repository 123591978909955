.Bion-logo {
    height: 8vmin;
    width: 40vmin;
    pointer-events: none;

  }
  .Register-link{
      text-align: center;
      margin-top: 1.5vmin;
  }
  .Register-text{
      text-decoration: underline;
      color: rgb(5, 116, 160);
  }
  .label{
      text-align: left;
     font-weight: 400;
     margin-top: 1rem;
  }
  [type=submit]{
    max-width: none !important;
    width: 100% !important;
    border-radius: 0.375rem !important;
}

div.container>div{
    height: 100vh;
}
.login-card{
    width: 25rem;
}