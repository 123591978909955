.section-tab > div.row {
  --bs-gutter-x: 0;
}

h1 {
  font-size: 24px;
  margin-bottom: 0;
  font-weight: 700;
  color: #010737 !important;
}

.text-primary {
  color: #0057ff !important;
}

.gap-8 {
  gap: 8px;
}

.bg-1 {
  background-color: #00a7ea !important;
  border: #00a7ea !important;
}

.bg-2 {
  background-color: #e82e7d !important;
}

.bg-primary {
  background-color: #00a7ea !important;
}

.pc-1 {
  color: #4cafe5;
}

.pc-2 {
  color: #e82e7d;
}

/* Modal */
.btn {
  border-radius: 3px;
}

/* Table Row */
.table-row {
  background-color: #fff;
  border-radius: 0;
}

.border {
  border: 1px solid #ebeef4 !important;
}

.table-row div i {
  font-size: 16px;
  color: #e2e2e2;
}

.table-row div i:hover {
  color: #404040;
}

.jj-box {
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
}

.jj-box:hover {
  background-color: #f6f6f6 !important;
}

.question-box {
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
}

.question-box:hover {
  background-color: #f6f6f6 !important;
  color: #404040;
}
.question-box1 {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
}
.accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #00a7e9;
}

/* Tabs */

/* #pills-tab {
    flex-wrap: nowrap;
} */

ul.nav-pills > li > .nav-link {
  color: #010737;
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: none;

  padding: 18px 19px 18px 19px; /* 63px left */
}

ul.nav-pills > li > .nav-link i {
  position: absolute;
  top: 50%;
  margin-top: -18px;
  left: 29px;
}

ul.nav-pills > li > .nav-link p {
  font-size: 20px;
  line-height: 41px;
  font-weight: 450;
  display: block;
  /* padding-left: 42px; */
  text-align: center;
  position: relative;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #0057ff;
  background-color: #fff !important;
}

.round-icon,
.teams-icon {
  display: inline-block;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center center;
}

.round-icon {
  width: 28px;
  height: 28px;
  background-image: url("../../icons/time-icon.svg");
  margin-right: 14px;
  transform: translateY(25%);
}

.teams-icon {
  width: 34px;
  height: 34px;
  background-image: url("../../icons/team-icon.svg");
  margin-right: 14px;
  transform: translateY(25%);
}

.nav-pills .nav-link.active .teams-icon,
.nav-pills .show > .nav-link .teams-icon,
.nav-pills .nav-link.active .round-icon,
.nav-pills .show > .nav-link .round-icon {
  filter: invert(21%) sepia(99%) saturate(3917%) hue-rotate(218deg)
    brightness(100%) contrast(109%); /* #0057FF */
}

/* Form */
.form-select {
  /* width: auto !important; */
  border-radius: 0;
}

.form-group label {
  font-size: 12px;
  line-height: 30px;
  margin-bottom: 4px;
}

.form-control,
.form-select {
  border-color: #cccccc;
  font-size: 14px;
  line-height: 30px;
  color: #010737;
  padding: 8px 8px 8px 15px !important; /* padding: 4px 8px 4px 15px; */
  border-radius: 3px !important;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f9f9f9;
}

/* input[type="text"] {
    border-radius: 0;
} */

.action-button button[type="button"] {
  border-radius: 66px !important;
  width: 138px !important;
  max-width: 200px;
  height: 48px;
  font-size: 13px;
  line-height: 36px;
  background-color: #4ca63d;
  border-color: #4ca63d;
  color: #fff;
}

button[type="button"] a {
  color: #fff !important;
}

.action-button.create-contest-button button[type="button"] {
  border: 1px solid #e65cae;
  border-radius: 26px !important;
    background-color: #e65cae !important;
    background-image: url("../../icons/plus-icon.svg");
    background-repeat: no-repeat;
   background-position: 20px;
   width: 200px !important;
   padding-left: 40px;
}

@media (max-width: 767px) {
  .action-button.create-contest-button button[type="button"] {
    position: absolute;
    bottom: 18px;
    right: 0px;
    width: 59px !important;
    height: 59px !important;
    border-radius: 50% !important;
    background-color: #e65cae !important;
    color: transparent !important;
    border: 1px solid #e65cae;
  }

  .action-button.create-contest-button button[type="button"]::before {
    display: none;
  }

  .action-button.create-contest-button button[type="button"]::after {
    content: " ";
    display: block;
    width: 18px;
    height: 18px;
    background-image: url("../../icons/plus-icon.svg");
    background-repeat: no-repeat;
    position: absolute;
    left: 20px;
    top: 20px;
  }
}

/* Card */
.card {
  border-radius: 0;
  box-shadow: 0;
  /* border: 1px solid #ebeef4 !important; */
}

.accordion-button:not(.collapsed) {
  color: #fff !important;
  background-color: #00a7e9 !important;
  font-size: 1.3rem;
}

.accordion-button:not(.collapsed)::after {
  filter: brightness(0%) invert(0%);
}

.main > .section > .row {
  --bs-gutter-x: 0rem;
  width: 100%;
  margin: 0;
}

input {
  border-radius: 0 !important;
}

.form-label {
  margin-bottom: 0.25rem;
}

/* @media only screen and (max-width: 600px) {
    .action-button button[type="button"] {
        width: 86px;
        max-width: 200px;
        height: 36px;
    }
} */

/* richa */
.breadcrumb {
  font-size: 14px;
  font-family: "MarkPro" !important;
  color: #899bbd;
  font-weight: 600;
}

.game-actionButton {
  display: flex;
  gap: 8px;
  margin: auto;
  justify-content: flex-end;
}

.game-actionButton .btn {
  width: 99px !important;
  height: 48px !important;
  font-family: "MarkPro Bold";
  font-size: 13px;
  color: #fff;
  border-radius: 66px;
}

.game-actionButton .btn-danger {
  background-color: #ff0000;
  border-color: #ff0000;
}

.game-actionButton .btn-primary {
  background-color: #4cafe5;
  border-color: #4cafe5;
}

.game-actionButton .btn-success {
  background-color: #4ca63d;
  border-color: #4ca63d;
}

.game-actionButton .btn-warning {
  background-color: #9100d4;
  border-color: #9100d4;
}

@media screen and (max-width: 400px) {
  .d2,
  .d4 {
    display: block;
    float: none;
  }
  .margin-10 {
    margin: 0.1rem !important;
  }
}

@media screen and (max-width: 767px) {
  .list-group-item .row div:first-child {
    text-align: center !important;
    justify-content: center;
  }
  .game-actionButton {
    margin-top: 8px;
    justify-content: center !important;
  }

}

@media screen and (max-width: 370px) {
  .game-actionButton {
    gap: 4px !important;
  }
  .game-actionButton button {
    padding-inline: 5px !important;
    width: auto !important;
  }
}

@media only screen and (max-width: 767px) {
  #order-first {
    order: -1 !important;
    padding: 0;
  }
}

@media only screen and (max-width: 991px) {
  #order-first {
    justify-content: flex-end;
  }
}

/* body::-webkit-scrollbar
{
    display: none;
    } */

.fa,
.fas {
  font-weight: 900;
  border-radius: 5px !important;
}

/* .d-none
    {
        display:none!important
    } */

.header-nav .nav-profile span {
  font-size: 18px;
  font-weight: 300 !important;
}

.width-320 {
  width: 320px;
}
/*  create-new-question */
.border-textEditor {
  border: 1px solid #dce7e7 !important;
}

.result-border {
  border: 1px solid black;

  height: 100px;
}

/* .border-textEditor {
    border: 1px solid #ced4da !important;
    height: 200px;
} */

.public-DraftStyleDefault-ltr {
  margin: 0.5rem !important;
}

.ant-table tfoot > tr > td,
.ant-table tfoot > tr > th,
.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
  overflow-wrap: break-word;
  padding: 2px;
  position: relative;
}


@media screen and (max-width: 400px) {
  .headingOne button {
    font-size: 14px;
  }
}