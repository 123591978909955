/**
* Template Name: NiceAdmin - v2.2.2
* Template URL: https://bootstrapmade.com/nice-admin-bootstrap-admin-html-template/
* Author: BootstrapMade.comAlternateGothic
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
:root {
  scroll-behavior: smooth;
}

@font-face {
  font-family: AlternateGothic;
  src: url("../../../src/fonts/AlternateGothic.ttf");
}

@font-face {
  font-family: "MarkPro";
  src: url("../../../src/fonts/MarkPro-Book.ttf");
}

@font-face {
  font-family: "MarkPro Bold";
  src: url("../../../src/fonts/MarkPro-Bold.ttf");
}

@font-face {
  font-family: "MarkPro Black";
  src: url("../../../src/fonts/MarkPro-Black.ttf");
}

@font-face {
  font-family: "MarkPro Light";
  src: url("../../../src/fonts/MarkPro-Light.ttf");
}

body {
  font-family: "MaroPro", sans-serif;
  background: #fff;
  color: #444444;
  /* background: transparent linear-gradient(119deg, #5B80CA 0%, #AA51A5 100%) 0% 0% no-repeat padding-box fixed; */
}

a {
  color: #00a7ea;
  text-decoration: none;
}

a:hover {
  color: #717ff5;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Nunito", sans-serif;
}

/*--------------------------------------------------------------
# Main
--------------------------------------------------------------*/
.main {
  /* padding: 20px 30px; */
  transition: all 0.3s;
}
.mainWithoutSideNavBar, .withoutSideBarNav {
  margin-left: 0px;
  /* padding: 20px 30px; */
  padding-top: 65px;
}
@media (max-width: 1199px) {
  .main {
    /* padding: 20px; */
  }
}
.team-section {
  margin-top: 80px;
  padding: 20px 30px;
  transition: all 0.3s;
}

@media (max-width: 500px) {
  .mainWithoutSideNavBar, .withoutSideBarNav {
    padding-top: 80px;
  }
}

/*--------------------------------------------------------------
# Page Title
--------------------------------------------------------------*/
.pagetitle {
  background-color: #f9f9f9;
  padding: 32px 0px 12px 51px;
  /* margin-bottom: 12px; */
}

@media (max-width: 991px) {
  .pagetitle {
    padding: 16px 0px 22px 20px;
  }
}

@media (max-width: 768px) {
  .pagetitle.dashboard-pagetitle {
    border-bottom: 1px solid #E0E0E0;
    padding-bottom: 0px;
  }
  .pagetitle.dashboard-pagetitle .breadcrumb {
    margin-bottom: 11px;
  }
}

.pagetitle h1 {
  font-family: "MarkPro Bold" !important;
  font-size: 36px;
  margin-bottom: 0px;
  font-weight: 700;
  color: #010737;
}

.page-title-button {
  margin-right: 51px;
}

@media (max-width: 991px) {
  .page-title-button {
    margin-right: 20px;
  }
}

@media (max-width: 767px) {
  .pagetitle h1 {
    font-size: 26px;
  }

  .page-title-button.create-contest-button {
    position: fixed;
    bottom: 18px;
    right: 20px;
    z-index: 100;
    margin-right: 0px;
  }
}

@media (min-width: 768px) {
  .page-title-button.create-contest-button {
    margin-top: 29px;
  }
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  background: #00a7ea;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}
.back-to-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}
.back-to-top:hover {
  background: #6776f4;
  color: #fff;
}
.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Override some default Bootstrap stylings
--------------------------------------------------------------*/
/* Dropdown menus */
.dropdown-menu {
  border-radius: 4px;
  padding: 10px 0;
  -webkit-animation-name: dropdown-animate;
  animation-name: dropdown-animate;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  border: 0;
  box-shadow: 0 5px 30px 0 rgba(82, 63, 105, 0.2);
}
.dropdown-menu .dropdown-header,
.dropdown-menu .dropdown-footer {
  text-align: center;
  font-size: 15px;
  padding: 10px 25px;
}
.dropdown-menu .dropdown-footer a {
  color: #444444;
  text-decoration: underline;
}
.dropdown-menu .dropdown-footer a:hover {
  text-decoration: none;
}
.dropdown-menu .dropdown-divider {
  color: #a5c5fe;
  margin: 0;
}
.dropdown-menu .dropdown-item {
  font-size: 14px;
  padding: 10px 15px;
  transition: 0.3s;
}
.dropdown-menu .dropdown-item i {
  margin-right: 10px;
  font-size: 18px;
  line-height: 0;
}
.dropdown-menu .dropdown-item:hover {
  background-color: #f6f9ff;
}

@media (min-width: 768px) {
  .dropdown-menu-arrow::before {
    content: "";
    width: 13px;
    height: 13px;
    background: #fff;
    position: absolute;
    top: -7px;
    right: 20px;
    transform-origin: center center;
    transform: rotate(45deg);
    border-top: 1px solid #eaedf1;
    border-left: 1px solid #eaedf1;
  }
}
@-webkit-keyframes dropdown-animate {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  0% {
    opacity: 0;
  }
}
@keyframes dropdown-animate {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  0% {
    opacity: 0;
  }
}
/* Light Backgrounds */
.bg-primary-light {
  background-color: #cfe2ff;
  border-color: #cfe2ff;
}

.bg-secondary-light {
  background-color: #e2e3e5;
  border-color: #e2e3e5;
}

.bg-success-light {
  background-color: #d1e7dd;
  border-color: #d1e7dd;
}

.bg-danger-light {
  background-color: #f8d7da;
  border-color: #f8d7da;
}

.bg-warning-light {
  background-color: #fff3cd;
  border-color: #fff3cd;
}

.bg-info-light {
  background-color: #cff4fc;
  border-color: #cff4fc;
}

.bg-dark-light {
  background-color: #d3d3d4;
  border-color: #d3d3d4;
}

/* Card */
.card {
  /* margin-bottom: 30px; */
  border: none;
  /* border-radius: 5px;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1); */
  padding: 0px 39px 15px;
  /* background-color: #f9f9f9; */
}

@media (max-width: 991px) {
  .card {
    padding: 0px 8px 24px;
  }
}

@media (max-width: 767px) {
  .card-1 {
    border-bottom: 1px solid #E0E0E0;
  }
}

.card.d2 {
  padding-bottom: 29px;
}

@media (max-width: 991px) {
  .card.d2 {
    background-color: #fff;
    padding-top: 37px;
  }
}

.card-header,
.card-footer {
  /* border-color: #ebeef4; */
  background-color: #fff;
  color: #798eb3;
  padding: 15px 0px;
}

.card-title {
  padding: 20px 0 15px 0;
  font-size: 18px;
  font-weight: 500;
  color: #012970;
  font-family: "Poppins", sans-serif;
}
.card-title span {
  color: #899bbd;
  font-size: 14px;
  font-weight: 400;
}
.card-body {
  padding: 0px;
}

.card-body .list-group {
  padding: 39px 46px 48px 21px;
  background-color: #fff;
}

@media (max-width: 991px) {
  .card-body .list-group {
    padding-right: 16px;
  }
}

@media (max-width: 767px) {
  .card-body .list-group {
    padding: 0px;
    padding-bottom: 120px;
    border-radius: 0;
  }
}

.card-body .list-group .list-group-item {
  border: 0;
  border-radius: none;
  color: #010737;
}

@media (max-width: 767px) {
  .card-body .list-group .list-group-item {
    padding: 20px !important;
    border-bottom: 1px solid #e0e0e0;
  }
}

.card-body .list-group .list-group-item h4 {
  font-family: "MarkPro Bold" !important;
  font-size: 20px !important;
  line-height: 40px;
}

@media (max-width: 767px) {
  .card-body .list-group .list-group-item h4 {
  }
}

.card-img-overlay {
  background-color: rgba(255, 255, 255, 0.6);
}

.card#order-first {
  padding-right: 21px;
  padding-bottom: 0;
  /* max-width: 161px; */
}

/* Alerts */
.alert-heading {
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
}

/* Close Button */
.btn-close {
  background-size: 25%;
}
.btn-close:focus {
  outline: 0;
  box-shadow: none;
}

/* Accordion */
.accordion-item {
  /* border: 1px solid #ebeef4; */
}

.accordion-button:focus {
  outline: 0;
  box-shadow: none;
}
.accordion-button:not(.collapsed) {
  color: #012970;
  background-color: #f6f9ff;
}

.accordion-flush .accordion-button {
  padding: 15px 0;
  background: none;
  border: 0;
}
.accordion-flush .accordion-button:not(.collapsed) {
  box-shadow: none;
  color: #00a7ea;
}
.accordion-flush .accordion-body {
  padding: 0 0 15px 0;
  color: #3e4f6f;
  font-size: 15px;
}

/* Breadcrumbs */
.breadcrumb {
  font-size: 13px;
  line-height: 32.5px;
  color:  4cafe5;
  font-weight: 450;
  margin-bottom: 20px;
}
.breadcrumb a {
  color:  4cafe5;
  transition: 0.3s;
}
.breadcrumb a:hover {
  color:  4cafe5;
}
.breadcrumb .breadcrumb-item::before {
  color:  4cafe5;
}
.breadcrumb .active {
  color:  4cafe5;
  font-weight: 450;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 5px;
}

.breadcrumb-item + .breadcrumb-item::before {
  padding-right: 3px;
}

/* Bordered Tabs */
.nav-tabs-bordered {
  border-bottom: 2px solid #ebeef4;
}
.nav-tabs-bordered .nav-link {
  margin-bottom: -2px;
  border: none;
  color: #2c384e;
}
.nav-tabs-bordered .nav-link:hover,
.nav-tabs-bordered .nav-link:focus {
  color: #00a7ea;
}
.nav-tabs-bordered .nav-link.active {
  background-color: #fff;
  color: #00a7ea;
  border-bottom: 2px solid #00a7ea;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.logo {
  line-height: 1;
}

.logo img {
  /* padding-left: 30px; */
}

@media (max-width: 991px) {
  /* .logo img {
    padding-left: 46px;
  } */
}
@media (min-width: 1200px) {
  .logo {
    /* width: 280px; */
    width: auto;
    margin-inline: auto;
  }
}
.logo img {
  max-height: 37px;
}
.logo span {
  font-size: 26px;
  font-weight: 700;
  color: #012970;
  font-family: "Nunito", sans-serif;
}

.menu-icon-wrap {
  width: 97px;
}

@media (max-width: 767px) {
  .menu-icon-wrap {
    width: 46px;
  }
}

.menu-icon {
  width: 24px;
  height: 14px;
  display: block;
  background-image: url(../../icons/menu-icon.svg);
  cursor: pointer;
}

.dashboard-menu-icon-wrap {
  position: absolute;
  right: 20px;
  top: -5px;
  z-index: 1000;
  display: none;
}

.dashboard-close-icon {
  font-size: 60px;
  color: white;
  cursor: pointer;
}

.mainWithoutSideNavBar .dashboard-menu-icon-wrap {
  display: none;
}

@media (max-width: 500px) {
  .dashboard-menu-icon-wrap {
    display: block;
  }
}

.header {
  /* transition: all 0.5s; */
  z-index: 997;
  height: 80px;
  /* box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1); */
  background-color: #fff;
  padding-left: 31px;
  /* margin-left: 236px; */
  /* Toggle Sidebar Button */
  /* Search Bar */
}

@media (max-width: 767px) {
  .header {
    padding-left: 22px;
  }
}

@media (max-width: 767px) {
  .header.open {
    padding-left: 10px;
  }
  .header.open .menu-icon-wrap {
    margin-right: 10px;
  }
}

@media (max-width: 600px) {
  .header {
    margin-left: 0px;
  }
}
.header .toggle-sidebar-btn {
  font-size: 16px;
  padding-left: 10px;
  cursor: pointer;
  color: #012970;
  width: 92px;
}
.header .search-bar {
  min-width: 360px;
  padding: 0 20px;
}
@media (max-width: 1199px) {
  .header .search-bar {
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    padding: 20px;
    box-shadow: 0px 0px 15px 0px rgba(1, 41, 112, 0.1);
    background: white;
    z-index: 9999;
    transition: 0.3s;
    visibility: hidden;
    opacity: 0;
  }
  .header .search-bar-show {
    top: 60px;
    visibility: visible;
    opacity: 1;
  }

  .header .toggle-sidebar-btn {
    font-size: 16px;
    padding-left: 10px;
    cursor: pointer;
    color: #012970;
  }

  .header .logo {
    margin-inline: auto;
  }
}

@media (max-width: 767px) {
  .header .logo {
    margin-left: 0px;
  }
}

@media (max-width: 375px) {
  .header .logo img{
    max-height: 26px;
  }
}

.header .search-form {
  width: 100%;
}
.header .search-form input {
  border: 0;
  font-size: 14px;
  color: #012970;
  border: 1px solid rgba(1, 41, 112, 0.2);
  padding: 7px 38px 7px 8px;
  border-radius: 3px;
  transition: 0.3s;
  width: 100%;
}
.form-check-input:checked {
  background-color: #00a7ea;
  border-color: #00a7ea;
}
.header .search-form input:focus,
.header .search-form input:hover {
  outline: none;
  box-shadow: 0 0 10px 0 rgba(1, 41, 112, 0.15);
  border: 1px solid rgba(1, 41, 112, 0.3);
}
.header .search-form button {
  border: 0;
  padding: 0;
  margin-left: -30px;
  background: none;
}
.header .search-form button i {
  color: #012970;
}

/*--------------------------------------------------------------
# Header Nav
--------------------------------------------------------------*/
.header-nav ul {
  list-style: none;
}
.header-nav > ul {
  margin: 0;
  padding: 0;
}
.header-nav .nav-icon {
  font-size: 20px;
  color: #012970;
}
.header-nav .nav-profile {
  color: #010737;
}
.header-nav .nav-profile img {
  max-height: 36px;
}
.header-nav .nav-profile span {
  font-size: 16px;
  font-weight: 450;
}
.header-nav .badge-number {
  position: absolute;
  inset: 4px 6px auto auto;
  font-weight: normal;
  font-size: 11px;
  padding: 3px 6px;
}
.header-nav .notifications .notification-item {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  transition: 0.3s;
}
.header-nav .notifications .notification-item i {
  margin: 0 20px 0 10px;
  font-size: 24px;
}
.header-nav .notifications .notification-item h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}
.header-nav .notifications .notification-item p {
  font-size: 13px;
  margin-bottom: 3px;
  color: #919191;
}
.header-nav .notifications .notification-item:hover {
  background-color: #f6f9ff;
}
.header-nav .messages .message-item {
  padding: 15px 10px;
  transition: 0.3s;
}
.header-nav .messages .message-item a {
  display: flex;
}
.header-nav .messages .message-item img {
  margin: 0 20px 0 10px;
  max-height: 40px;
}
.header-nav .messages .message-item h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #444444;
}
.header-nav .messages .message-item p {
  font-size: 13px;
  margin-bottom: 3px;
  color: #919191;
}
.header-nav .messages .message-item:hover {
  background-color: #f6f9ff;
}
.header-nav .profile {
  min-width: 240px;
  padding-bottom: 0;
}
.header-nav .profile .dropdown-header h6 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 600;
  color: #444444;
}
.header-nav .profile .dropdown-header span {
  font-size: 14px;
}
.header-nav .profile .dropdown-item {
  font-size: 14px;
  padding: 10px 15px;
  transition: 0.3s;
}
.header-nav .profile .dropdown-item i {
  margin-right: 10px;
  font-size: 18px;
  line-height: 0;
}

.header-nav .nav-profile .dropdown-toggle {
  margin-right: 22px;
  position: relative;
}

.header-nav .nav-profile .dropdown-toggle:after {
  border: none;
  background-image: url("../../icons/black-down-icon.svg");
  width: 14px;
  height: 9px;
  display: inline-block;
  position: absolute;
  top: 8px;
  right: -22px;
  scale: 0.85;
}
.header-nav .profile .dropdown-item:hover {
  background-color: #f6f9ff;
}

.dashboard-icon {
  display: inline-block;
  margin-right: 14px;
  width: 24px;
  height: 19px;
  background-image: url("../../icons/dashboard-icon.svg");
  background-repeat: no-repeat;
}

.games-icon {
  display: inline-block;
  margin-right: 14px;
  width: 30px;
  height: 30px;
  background-image: url("../../icons/games-icon.svg");
  background-repeat: no-repeat;
}

/*--------------------------------------------------------------
# Sidebar
--------------------------------------------------------------*/
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 997;
  transition: all 0.3s;
  padding: 20px;
  padding-right: 125px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #aab7cf transparent;
  /* box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1); */
  background-color: #4cafe5;
  color: #fff;
}
@media (min-width: 500px) {
  .sidebar {
    width: 236px;
    padding-right: 20px;
  }
}
.sidebar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #fff;
}
.sidebar::-webkit-scrollbar-thumb {
  background-color: #aab7cf;
}

@media (min-width: 1200px) {
  .main,
  .mainWithoutSideNavBar,
  #footer {
    margin-left: 236px;
  }
}
@media (max-width: 1199px) {
  .toggle-sidebar .sidebar {
    left: 0;
  }
}
@media (min-width: 1200px) {
  .toggle-sidebar .main,
  .mainWithoutSideNavBar,
  .toggle-sidebar #footer {
    margin-left: 0;
  }
  .toggle-sidebar .sidebar {
    left: -101vw;
  }
}

.sidebar-nav {
  padding: 0;
  margin: 0;
  list-style: none;
}
.sidebar-nav li {
  padding: 0;
  margin: 0;
  list-style: none;
}
.sidebar-nav .nav-item {
  margin-bottom: 7px;
}
.sidebar-nav .nav-heading {
  font-size: 11px;
  text-transform: uppercase;
  color: #899bbd;
  font-weight: 600;
  margin: 10px 0 5px 15px;
}
.sidebar-nav .nav-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 450;
  line-height: 1.5em;
  color: #fff;
  transition: 0.3;
  background: transparent;
  padding: 10px 11px;
  border-radius: 4px;
}

.sidebar-nav .nav-link span:nth-of-type(2) {
  margin-right: auto;
}

.sidebar-nav .nav-link i {
  font-size: 24px;
  margin-right: 20px;
  color: #fff;
}

.sidebar-nav .nav-link i:nth-of-type(2) {
  font-size: 14px;
}

.sidebar-nav .nav-link.collapsed {
  color: #fff;
  background: transparent;
}
.sidebar-nav .nav-link.collapsed i {
  color: #fff;
}
.sidebar-nav .nav-link:hover {
  color: #010737;
  background: transparent;
}
.sidebar-nav .nav-link:hover i {
  color: #010737;
}

.sidebar-nav .nav-link:hover .games-icon {
  background-image: url("../../icons/black-games-icon.svg");
}
.sidebar-nav .nav-link .bi-chevron-down {
  margin-right: 0;
  transition: transform 0.2s ease-in-out;
}
.sidebar-nav .nav-link:not(.collapsed) .bi-chevron-down {
  transform-origin: center center;
  transform: rotate(180deg);
}

.sidebar-nav .games-link {
  align-items: flex-end;
  line-height: 20px;
}

.sidebar-nav .nav-link.collapsed .down-icon {
  display: inline-block;
  width: 17px;
  height: 9px;
  scale: 0.85;
  background-image: url("../../icons/down-arrow.svg");
  background-repeat: no-repeat;
  margin-bottom: 5px;
  /* margin-left: 49px; */
}

.sidebar-nav .nav-link:hover .down-icon {
  background-image: url("../../icons/black-down-icon.svg");
}
.sidebar-nav .nav-link .down-icon {
  margin-right: 0;
  transition: transform 0.2s ease-in-out;
}
.sidebar-nav .nav-link:not(.collapsed) .down-icon {
  display: inline-block;
  width: 17px;
  height: 9px;
  scale: 0.85;
  background-image: url("../../icons/down-arrow.svg");
  background-repeat: no-repeat;
  transform-origin: center center;
  transform: rotate(180deg);
  margin-bottom: 5px;
  /* margin-left: 49px; */
}

.sidebar-nav .nav-link:not(.collapsed):hover .down-icon {
  background-image: url("../../icons/black-down-icon.svg");
}

.sidebar-nav .nav-content {
  padding: 5px 0 0 0;
  margin: 0;
  list-style: none;
  
}
.sidebar-nav .nav-content a {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  transition: 0.3;
  padding: 10px 11px 10px 30px;
  transition: 0.3s;
  border-top: 1px solid white;
}

.sidebar-nav .nav-content a i {
  font-size: 6px;
  margin-right: 8px;
  line-height: 0;
  border-radius: 50%;
}
.sidebar-nav .nav-content a:hover,
.sidebar-nav .nav-content a.active {
  color: #010737;
}
.sidebar-nav .nav-content a.active i {
  background-color: #010737;
}

.sidebar-nav > li:first-of-type {
  background-color: #fff;
}

.sidebar-nav > li:first-of-type a {
  padding-left: 15px;
  padding-right: 15px;
}

.sidebar-nav > li:first-of-type span {
  color: #010737;
}

.sidebar-nav li:first-of-type i {
  color: #010737;
}

.sidebar-nav #tables-nav li a {
  padding-left: 56px;
}

.sidebar-nav .nav-content ul li a {
  border-top: 0 none;
  padding-left: 6px;
}

.sidebar-nav #tables-nav-ques li .down-icon {
  margin-right: -3px !important;
}

.sidebar-nav .games-link .down-icon {
  margin-right: -3px !important;
}

/*--------------------------------------------------------------
# Create Contest
--------------------------------------------------------------*/

.create-contest-form-section {
  background-color: #f9f9f9;
}

.create-teams-section {
  padding: 39px 51px 48px 51px;
  position: relative;
}

/* @media (max-width: 767px) {
  .create-teams-section {
    padding: 21px 20px 48px 20px;
  }
} */

@media (max-width: 991px) {
  .create-teams-section {
    padding: 0;
  }

  .create-teams-title {
    padding: 21px 25px 0px;
  }
}

.create-teams-section h1 {
  margin-bottom: 26px;
  font-family: "MarkPro Bold" !important;
}

@media (max-width: 767px) {
  .create-teams-section h1 {
    margin-bottom: 0px;
  }
}

.create-teams-section label {
  color: #010737 !important;
  line-height: 26px;
  margin-left: 0px;
}

.create-teams-section .btn-add-team {
  position: absolute;
  top: -10px;
  width: 49px !important;
  height: 49px !important;
  border-radius: 50% !important;
  background-color: #e65cae !important;
}

.create-teams-section .btn-add-team::before {
  display: none;
}

.create-teams-section .btn-add-team::after {
  content: " ";
  display: block;
  width: 18px;
  height: 18px;
  background-image: url("../../icons/plus-icon.svg");
  background-repeat: no-repeat;
  position: absolute;
  left: 15px;
  top: 15px;
}

.create-teams-section .btn-add-team-game,
.create-teams-section .btn-add-team {
  position: relative;
  top: -10px;
  width: 49px !important;
  height: 49px !important;
  border-radius: 50% !important;
  background-color: #e65cae !important;
}



.create-teams-section .btn-add-team-game::before,
.create-teams-section .btn-add-team::before {
  display: none;
}

.create-teams-section .btn-add-team-game::after,
.create-teams-section .btn-add-team::after {
  content: " ";
  display: block;
  width: 18px;
  height: 18px;
  background-image: url("../../icons/plus-icon.svg");
  background-repeat: no-repeat;
  position: absolute;
  left: 15px;
  top: 15px;
}

.create-teams-section .bottom-button {
  float: right;
  position: relative;
  margin-right: 20px;
  margin-top: 6px;
}

/* @media (max-width: 767px) {
  .create-teams-section .btn-add-team,
  .create-teams-section .btn-add-team  {
    width: 59px !important;
    height: 59px !important;
    right: 20px;
  }
  .create-teams-section .btn-add-team::after {
    left: 20px;
    top: 20px;
  }
} */

.busterCards .table-row {
  padding-right: 39px;
  align-items: flex-start !important;
}

.busterCards .table-row > div:nth-of-type(1),
.busterCards .table-row > div:last-of-type {
  height: 48px !important;
  padding: 0;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* @media (max-width: 991px) {
  .busterCards .table-row {
    padding-right: 0px;
  }
} */
@media (max-width: 991px) {
  .busterCards .table-row {
    padding: 21px 20px 0px 20px;
    border-bottom: 1px solid #e0e0e0;
  }
}

.busterCards .team-col {
  padding: 10px 10px 10px 0px;
}

@media (max-width: 991px) {
  .busterCards-col {
    margin-left: 24px;
  }
}

.busterCards .busterCards-col {
  padding: 0px !important;
}

/* .busterCards .col-lg-7 {
  max-width: 553px;
} */

.busterCards button {
  font-size: 16px;
  line-height: 40px;
  color: #000;
  width: 48px;
  height: 48px;
  border-radius: 3px;
}

.busterCards button.btn-success {
  color: #fff;
}

.busterCards button.disabled {
  border: 1px solid #cccccc;
  border-radius: 3px;
  opacity: 1;
}

.team-serial-number {
  width: 24px;
  height: 48px;
  padding: 0px !important;
  margin: 10px 10px 10px 0px;
  font-family: "MarkPro Bold";
  line-height: 48px;
}

@media (min-width: 992px) {
  .team-serial-number {
    display: none;
  }
}

.delete-icon-wrap {
  height: 48px;
  padding: 0px !important;
  margin: 10px;
  justify-content: center;
}

@media (max-width: 1199px) {
  .delete-icon-wrap {
    margin-inline: 0;
  }
}

@media (min-width: 1200px) {
  .delete-icon-wrap {
    max-width: 48px;
  }
}

.delete-icon {
  display: block;
  width: 26px;
  height: 26px;
  background-image: url("../../icons/delete-icon.svg");
  background-repeat: no-repeat;
  cursor: pointer;
}

/*--------------------------------------------------------------
# Scoreboard
--------------------------------------------------------------*/

.scoreboard-header {
  background-color: #fff;
  padding: 32px 40px 29px 51px;
  /* border-bottom: 1px solid #E0E0E0; */
}

@media (max-width: 991px) {
  .scoreboard-header {
    padding: 15px 20px 27px;
    display: block !important;
  }
}

@media (max-width: 767px) {
  .scoreboard-header {
    background-color: #f9f9f9;
    border-top: 1px solid #e0e0e0;
  }
}

.scoreboard-header h1 {
  font-family: "MarkPro Bold" !important;
  font-size: 24px;
  line-height: 60px;
}

.scoreboard-header .btn {
  font-family: "MarkPro Bold" !important;
  border-radius: 66px;
  font-size: 13px;
  line-height: 11px;
  padding: 12px 25px 10px;
}

@media (max-width: 767px) {
  .scoreboard-header .d-flex.gap-8 {
    /* justify-content: center; */
    gap: 16px;
    grid-gap: 16px;
  }
  .scoreboard-header .btn {
    line-height: 16px;
    padding: 15px 16px;
  }
}

.scoreboard-header .btn-primary {
  background-color:  4cafe5;
  border-color:  4cafe5;
}

.scoreboard-header .btn-danger {
  background-color: #ff0000;
  border-color: #ff0000;
}

.scoreboard-header .btn-success {
  background-color: #4ca63d;
  border-color: #4ca63d;
}

/* ----------------- Accordion ------------------------------ */

.score-editor #round-data,
.score-editor #teams-data {
  border: none;
  border-radius: 0;
}

#round-data .accordion-item,
#teams-data .accordion-item {
  border: 0;
  /* border-top: 1px solid #e0e0e0; */
  padding: 32px 61px 32px 43px;
  border-radius: 0;
}

.accordion-header button {
  width: 100% !important;
}

@media (max-width: 991px) {
  #round-data .accordion-item,
  #teams-data .accordion-item {
    padding: 0px;
  }
  .accordion-header {
    /* padding: 32px 20px 32px 23px; */
    padding: 5px 20px 0px;
    line-height: 50px;
  }
}

/* @media (max-width: 767px) {
  #round-data .accordion-item, #teams-data .accordion-item {
    padding: 15px 20px 17px;
  }
} */

#round-data .accordion-body tbody > div,
#teams-data .accordion-body tbody > div {
  margin-top: 8px;
}

@media (max-width: 991px) {
  #round-data .accordion-body tbody > div,
  #teams-data .accordion-body tbody > div {
    margin-top: 0px;
  }
}

#round-data .accordion-item:first-of-type .accordion-button,
#teams-data .accordion-item:first-of-type .accordion-button {
  border-radius: 0;
  padding-top: 0px;
  padding-left: 40px;
  padding-right: 0px;
  padding-bottom: 0px;
  position: relative;
}

#round-data .accordion-item:first-of-type .accordion-button:before {
  content: " ";
  display: block;
  width: 28px;
  height: 28px;
  background-image: url("../../icons/time-icon.svg") !important;
  background-repeat: no-repeat !important;
  position: absolute;
  top: 50%;
  left: 0px;
  margin-top: -14px;
}

#teams-data .accordion-item:first-of-type .accordion-button {
  padding-left: 54px;
}

#teams-data .accordion-item:first-of-type .accordion-button:before {
  content: " ";
  display: block;
  width: 34px;
  height: 17px;
  background-image: url("../../icons/team-icon.svg") !important;
  background-repeat: no-repeat !important;
  position: absolute;
  top: 50%;
  left: 4px;
  margin-top: -9px;
}

#round-data .accordion-button:not(.collapsed),
#teams-data .accordion-button:not(.collapsed) {
  background: transparent !important;
  border: 0 !important;
  box-shadow: none;
  color: #010737 !important;
  font-family: "MarkPro Bold" !important ;
  font-size: 20px;
  /* line-height: 50px; */
}

#round-data .accordion-button.collapsed,
#teams-data .accordion-button.collapsed {
  background: transparent !important;
  color: #010737 !important;
  font-family: "MarkPro Bold" !important ;
  font-size: 20px;
}

#round-data .accordion-body,
#teams-data .accordion-body {
  padding: 0;
}

@media (max-width: 991px) {
  #round-data .accordion-body,
  #teams-data .accordion-body {
    padding: 5px 0px 10px;
    /* border-top: 1px solid #E0E0E0; */
  }
}

#round-data .accordion-body .accordion-item,
#teams-data .accordion-body .accordion-item {
  padding: 0px;
  border: 0;
  justify-content: space-between;
  align-items: flex-start !important;
}

@media (max-width: 991px) {
  #round-data .accordion-body .accordion-item,
  #teams-data .accordion-body .accordion-item {
    padding-inline: 8px;
    /* border-top: 1px solid #e0e0e0; */
  }
}

#round-data .accordion-body .accordion-item .text-start,
#teams-data .accordion-body .accordion-item .text-start {
  font-family: "MarkPro Black" !important;
  font-size: 16px;
  line-height: 20px;
  padding: 10px 8px !important;
}

#round-data .accordion-body .fw-20,
#teams-data .accordion-body .fw-20 {
  font-size: 24px !important;
  line-height: 20px;
  font-family: "MarkPro Bold" !important;
  max-width: 48px !important;
  padding: 10px 0px 20px 0 !important;
}

@media (max-width: 991px) {
  #round-data .accordion-body .fw-20,
  #teams-data .accordion-body .fw-20 {
    padding-right: 12px !important;
  }
}

@media (max-width: 767px) {
  #round-data .accordion-body .accordion-item .text-start,
  #teams-data .accordion-body .accordion-item .text-start {
    line-height: 20px;
    padding-block: 0px !important;
  }

  #round-data .accordion-body .fw-20,
  #teams-data .accordion-body .fw-20 {
    /* display: none; */
  }
}

#teams-data .question-box,
#teams-data .question-box1 {
  height: 48px;
  max-width: 48px;
  width: 48px;
  margin: 10px !important;
  padding: 0px;
  border-radius: 3px !important;
}

#teams-data .table-row td .row div:nth-of-type(11),
#teams-data .table-row td .row div:nth-of-type(12) {
  border: 1px solid #cccccc;
}

#round-data button[id*="pp"] {
  border-color: #cccccc;
}

#teams-data .question-box1:empty {
  border: none !important;
}

/*--------------------------------------------------------------
# Dashboard
--------------------------------------------------------------*/
/* Filter dropdown */
.dashboard .filter {
  position: absolute;
  right: 0px;
  top: 15px;
}
.dashboard .filter .icon {
  color: #aab7cf;
  padding-right: 20px;
  padding-bottom: 5px;
  transition: 0.3s;
  font-size: 16px;
}
.dashboard .filter .icon:hover,
.dashboard .filter .icon:focus {
  color: #00a7ea;
}
.dashboard .filter .dropdown-header {
  padding: 8px 15px;
}
.dashboard .filter .dropdown-header h6 {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #aab7cf;
  margin-bottom: 0;
  padding: 0;
}
.dashboard .filter .dropdown-item {
  padding: 8px 15px;
}

/* Info Cards */
.dashboard .info-card {
  padding-bottom: 10px;
}
.dashboard .info-card h6 {
  font-size: 28px;
  color: #012970;
  font-weight: 700;
  margin: 0;
  padding: 0;
}
.dashboard .card-icon {
  font-size: 32px;
  line-height: 0;
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  flex-grow: 0;
}
.dashboard .sales-card .card-icon {
  color: #00a7ea;
  background: #f6f6fe;
}
.dashboard .revenue-card .card-icon {
  color: #2eca6a;
  background: #e0f8e9;
}
.dashboard .customers-card .card-icon {
  color: #ff771d;
  background: #ffecdf;
}

/* Activity */
.dashboard .activity {
  font-size: 14px;
}
.dashboard .activity .activity-item .activite-label {
  color: #888;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  min-width: 64px;
}
.dashboard .activity .activity-item .activite-label::before {
  content: "";
  position: absolute;
  right: -11px;
  width: 4px;
  top: 0;
  bottom: 0;
  background-color: #eceefe;
}
.dashboard .activity .activity-item .activity-badge {
  margin-top: 3px;
  z-index: 1;
  font-size: 11px;
  line-height: 0;
  border-radius: 50%;
  flex-shrink: 0;
  border: 3px solid #fff;
  flex-grow: 0;
}
.dashboard .activity .activity-item .activity-content {
  padding-left: 10px;
  padding-bottom: 20px;
}
.dashboard .activity .activity-item:first-child .activite-label::before {
  top: 5px;
}
.dashboard .activity .activity-item:last-child .activity-content {
  padding-bottom: 0;
}

/* News & Updates */
.dashboard .news .post-item + .post-item {
  margin-top: 15px;
}
.dashboard .news img {
  width: 80px;
  float: left;
  border-radius: 5px;
}
.dashboard .news h4 {
  font-size: 15px;
  margin-left: 95px;
  font-weight: bold;
  margin-bottom: 5px;
}
.dashboard .news h4 a {
  color: #012970;
  transition: 0.3s;
}
.dashboard .news h4 a:hover {
  color: #00a7ea;
}
.dashboard .news p {
  font-size: 14px;
  color: #777777;
  margin-left: 95px;
}

/* Recent Sales */
.dashboard .recent-sales {
  font-size: 14px;
}
.dashboard .recent-sales .table thead {
  background: #f6f6fe;
}
.dashboard .recent-sales .table thead th {
  border: 0;
}
.dashboard .recent-sales .dataTable-top {
  padding: 0 0 10px 0;
}
.dashboard .recent-sales .dataTable-bottom {
  padding: 10px 0 0 0;
}

/* Top Selling */
.dashboard .top-selling {
  font-size: 14px;
}
.dashboard .top-selling .table thead {
  background: #f6f6fe;
}
.dashboard .top-selling .table thead th {
  border: 0;
}
.dashboard .top-selling .table tbody td {
  vertical-align: middle;
}
.dashboard .top-selling img {
  border-radius: 5px;
  max-width: 60px;
}

/*--------------------------------------------------------------
# Icons list page
--------------------------------------------------------------*/
.iconslist {
  display: grid;
  max-width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 1.25rem;
  padding-top: 15px;
}
.iconslist .icon {
  background-color: #fff;
  border-radius: 0.25rem;
  text-align: center;
  color: #012970;
  padding: 15px 0;
}
.iconslist i {
  margin: 0.25rem;
  font-size: 2.5rem;
}
.iconslist .label {
  font-family: var(--bs-font-monospace);
  display: inline-block;
  width: 100%;
  overflow: hidden;
  padding: 0.25rem;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #666;
}

/*--------------------------------------------------------------
# Profie Page
--------------------------------------------------------------*/
.profile .profile-card img {
  max-width: 120px;
}
.profile .profile-card h2 {
  font-size: 24px;
  font-weight: 700;
  color: #2c384e;
  margin: 10px 0 0 0;
}
.profile .profile-card h3 {
  font-size: 18px;
}
.profile .profile-card .social-links a {
  font-size: 20px;
  display: inline-block;
  color: rgba(1, 41, 112, 0.5);
  line-height: 0;
  margin-right: 10px;
  transition: 0.3s;
}
.profile .profile-card .social-links a:hover {
  color: #012970;
}
.profile .profile-overview .row {
  margin-bottom: 20px;
  font-size: 15px;
}
.profile .profile-overview .card-title {
  color: #012970;
}
.profile .profile-overview .label {
  font-weight: 600;
  color: rgba(1, 41, 112, 0.6);
}
.profile .profile-edit label {
  font-weight: 600;
  color: rgba(1, 41, 112, 0.6);
}
.profile .profile-edit img {
  max-width: 120px;
}

/*--------------------------------------------------------------
# F.A.Q Page
--------------------------------------------------------------*/
.faq .basic h6 {
  font-size: 18px;
  font-weight: 600;
  color: #00a7ea;
}
.faq .basic p {
  color: #6980aa;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-box {
  padding: 28px 30px;
}
.contact .info-box i {
  font-size: 38px;
  line-height: 0;
  color: #00a7ea;
}
.contact .info-box h3 {
  font-size: 20px;
  color: #012970;
  font-weight: 700;
  margin: 20px 0 10px 0;
}
.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}
.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}
.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}
.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
}
.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}
.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 0;
}
.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #00a7ea;
}
.contact .php-email-form input {
  padding: 10px 15px;
}
.contact .php-email-form textarea {
  padding: 12px 15px;
}
.contact .php-email-form button[type="submit"] {
  background: #00a7ea;
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}
.contact .php-email-form button[type="submit"]:hover {
  background: #5969f3;
}
@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Error 404
--------------------------------------------------------------*/
.error-404 {
  padding: 30px;
}
.error-404 h1 {
  font-size: 180px;
  font-weight: 700;
  color: #00a7ea;
  margin-bottom: 0;
  line-height: 150px;
}
.error-404 h2 {
  font-size: 24px;
  font-weight: 700;
  color: #012970;
  margin-bottom: 30px;
}
.error-404 .btn {
  background: #51678f;
  color: #fff;
  padding: 8px 30px;
}
.error-404 .btn:hover {
  background: #3e4f6f;
}
@media (min-width: 992px) {
  .error-404 img {
    max-width: 50%;
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  padding: 20px 0;
  font-size: 14px;
  transition: all 0.3s;
  border-top: 1px solid #cddfff;
}
.footer .copyright {
  text-align: center;
  color: #012970;
}
.footer .credits {
  padding-top: 5px;
  text-align: center;
  font-size: 13px;
  color: #012970;
}
.accord-color {
  background: #00a7e9 !important;
  color: white;
  font-size: 1.3rem;
}
.refresh-btn {
  border-radius: 0.25rem !important;
  width: 150px;
  max-width: 300px;
}
.btn-sm {
  line-height: 110%;
}

.close-cross {
  position: absolute;
  font-size: 50px;
  top: -15px;
  right: 10px;
  color: #999999;
  cursor: pointer; 
}

#navMain.hide {
  top: -80px;
}

#navMain.inactive .sidebar {
    left: -110vw;
}

#navMain.inactive .main {
    margin-left: 0;
}

.sidebar-close-icon {
  position: absolute;
  top: -5px;
  right: 15px;
  font-size: 50px;
  cursor: pointer;
}

#selectRound, 
#selectTeam {
  border: 2px solid #000000;
  font-size: 20px;
  font-family: "MarkPro Bold";
  padding: 10px 20px 8px 50px !important;
  background: url('../../icons/time-icon.svg') 20px center no-repeat;
  background-size: 20px;
  border-radius: 8px !important;
}

#selectTeam {
  background: url('../../icons/team-icon.svg') 20px center no-repeat;
  background-size: 19px;
}



@media (max-width: 767px) {
  #selectTeam {
    margin-top: 10px;
  }
  .hide-small {
    display: none !important; 
  }
}

.button-scroller {
  width: 120%;
  height: 60px;
  position: relative;
  left: -20px;
  padding-left: 20px;
  overflow: hidden;
  overflow-x: scroll;
  -ms-overflow-style: none; 
  scrollbar-width: none; 
}

.button-scroller::-webkit-scrollbar, 
.button-scroller::-webkit-scrollbar {
  display: none;
}


.scoreboard-header {
  height: 140px;
}

.scoreboard-header .text-start h1 {
  margin-right: 20px;
}

.button-scroll {
  margin: 0;
  padding: 0;
  width: 900px;
}

.button-scroll li {
  margin: 4px 0 0 0;
  padding: 0;
  display: inline-block !important;
  margin-right: 5px;
  list-style-type: none !important;
}

.btn-secondary.loading {
      background: #4cafe5;
}

.btn-load-teams {
  background: transparent;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #010737;
  margin-left: 60px;
  padding: 0 20px;
  position: relative;
  right: 40px;
  top: -5px;
}