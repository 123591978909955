@font-face {
  font-family: AlternateGothic;
  src: url("../../../src/fonts/AlternateGothic.ttf");
}

@font-face {
  font-family: "MarkPro";
  src: url("../../../src/fonts/MarkPro-Book.ttf");
}

@font-face {
  font-family: "MarkPro Bold";
  src: url("../../../src/fonts/MarkPro-Bold.ttf");
}

@font-face {
  font-family: "MarkPro Black";
  src: url("../../../src/fonts/MarkPro-Black.ttf");
}

@font-face {
  font-family: "MarkPro Light";
  src: url("../../../src/fonts/MarkPro-Light.ttf");
}

.scoreboard-body {
  /* background: transparent linear-gradient(119deg, #5B80CA 0%, #AA51A5 100%) 0% 0% no-repeat padding-box fixed; */
  background: black;
  padding: 0 62px 151px;
  font-family: "MarkPro";
  font-size: 26px;
  display: flex;
  flex-direction: column;
  gap: 3vh;
  min-height: 100vh !important;
  background-image: url("../images/leader-board-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  overflow-y: hidden;
}

@media (max-width: 768px) {
  .scoreboard-body {
    padding: 25px 20px;
    overflow-x: hidden;
  }
}

.leaderBoard-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 80px 66px 46px 66px;
  align-items: center;
  z-index: 1000;
  background: linear-gradient(0deg, #020209, #020209, transparent);
}

@media only screen and (max-width: 768px) {
  .leaderBoard-footer {
    padding: 0 20px 32px;
  }
  img {
    display: none;
  }
  button {
    /* width: 40% !important; */
  }
}

/* @media only screen and (max-width: 767px) {
    body{
        padding: 5%;
    }
} */

.row {
  --bs-gutter-x: 1.5rem;
  width: 100%;
  margin: 0;
}

.pp-bg-color {
  background-color: rgba(145, 0, 212, 0.31) !important;
}

.list-bg-color {
  background-color: rgba(76, 175, 229, 0.31) !important;
}

.refresh-btn {
  border-radius: 120px !important;
  width: 168px;
  max-width: 168px;
  height: 62px;
  background-color: #4ca63d;
  border-color: #4ca63d;
  color: #fff;
  font-family: "MarkPro Bold";
  font-size: 24px;
}

@media (max-width: 768px) {
  .refresh-btn {
    width: 140px;
    height: 48px;
    font-size: 0px;
  }
  .refresh-btn span {
    font-size: 24px;
  }
}

.refresh-btn.btn-warning:hover,
.refresh-btn.btn-warning:focus,
.refresh-btn.btn-warning:active {
  background-color: #4ca63d;
  border-color: #4ca63d;
}

.refresh-btn.bg-1:hover,
.refresh-btn.bg-1:focus,
.refresh-btn.bg-1:active {
  background-color: #4cafe5;
  border-color: #4cafe5;
  color: #fff;
}

/* LeaderBoard */
#layout {
  top: -132px;
  left: -30px;
  height: 1304px;
  transform: matrix(0, -1, 1, 0, 0, 0);
  text-align: center;
  font: normal normal normal 297px/356px Alternate Gothic No2 D;
  letter-spacing: 0px;
  color: #cccccc;
  opacity: 0.06;
  overflow: hidden;
  z-index: -1;
}

/* Logos */
.logos {
  width: fit-content;
  height: 79px;
}

/* @media (max-width: 550px) {
.logos {  
  height: 56px;
}
}*/

@media (max-width: 768px) {
  .logos.logo-header {
    display: block;
    margin-inline: auto;
    height: 46px;
  }
  .logos.logo-footer {
    display: none;
  }
}

@media (min-width: 769px) {
  .logos.logo-header {
    display: none;
  }
  .logos.logo-footer {
    display: block;
  }
}

/* Top Scorer */
/* .top-scorer-team{
    height: 105px;
    margin: 10px 0;
    
} */

/* .rank-badge{
    position: absolute;
    right: 5px;
    bottom: -1vh;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    color: #fff;
    opacity: 0.1;
    font-size: 48px;
}
 */

.top-scorer {
  height: 100%;
  display: flex;
  justify-content: center;
  z-index: 999;
  position: relative;
  align-items: center;
}

/* .score{
    top:-10%;
    clip-path: polygon(20% 0, 100% 0%, 80% 100%, 0% 100%);
    height: 120% !important;
    border: 3px solid #fff;
} */

/* .score::before {
    content: "";
    display: block;
    position: relative;
    height: 90%;
    width: 100%;
    background-color: #fff;
    clip-path: inherit;
} */

.top-scorer-team {
  /* width: 274px; */
  /* height: 396px; */
  height: 342px;
  margin-inline: auto;
  color: #fff;
  opacity: 0;
  transition: opacity 2s 0.5s;
  /* margin-bottom: -54px; */
}
/* @media (min-width: 768px) and (max-width: 970px) {
    .top-scorer-team {
        width: 200px;
    }
} */

.show-trophy-1 {
  opacity: 1;
  transition-property: opacity;
  transition-duration: 1s;
  transition-timing-function: linear;
}

.show-trophy-2 {
  opacity: 1;
  transition-property: opacity;
  transition-duration: 1s;
  transition-timing-function: linear;
  transition-delay: 2s;
}

.show-trophy-3 {
  opacity: 1;
  transition-property: opacity;
  transition-duration: 1s;
  transition-timing-function: linear;
  transition-delay: 3.5s;
}

@media (max-width: 768px) {
  .top-scorer-team {
    height: 58px;
    margin-bottom: 19px;
  }
  .show-trophy-2,

  .show-trophy-3 {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 970px) {
  .top-scorer-team {
    height: 275px;
  }
}
@media (min-width: 971px) {
  .top-scorer-team {
    width: 274px;
  }
}

.top-teamNames {
  font-family: "MarkPro Black";
  font-size: 34px;
  position: absolute;
  color: black;
  line-height: 130%;
}



.top-teamNames.first-position {
  left: 10px;
  top: 100px;
  z-index: 102;
  width: 340px;
  text-align: center;
  font-size: 60px;
  background: -webkit-linear-gradient(180deg, #000000, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  mix-blend-mode: difference;
}

@media (max-width: 550px) {
  .top-teamNames.first-position {
    left: 0%;
    top: 130px;
    width: 100%;
    margin-top: 0;
    height: 100vh !important;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-size: 40px;
  }
}
  
@media (min-width: 551px) and (max-width: 768px) {
.top-teamNames.first-position {
  left: 0px;
  top: 0;
  width: 100%;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -52vw;
  }
}

@media (min-width: 700px) and (max-width: 768px) {
  .top-teamNames.first-position {
    margin-top: -50vw;
  }
}
.top-teamNames.second-position {
  left: -150px;
  top: 160px;
  z-index: 100;
  width: 270px;
  text-align: center;
  font-size: 50px;
  background: -webkit-linear-gradient(180deg, #000000, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  mix-blend-mode: difference;
}
@media (max-width: 768px) {
  .top-teamNames.second-position {
    display: none;
  }
}
.top-teamNames.third-position {
  left: 190px;
  top: 210px;
  z-index: 99;
  width: 250px;
  text-align: center;
  font-size: 50px;
  background: -webkit-linear-gradient(180deg, #000000, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  mix-blend-mode: difference;
}
@media (max-width: 768px) {
  .top-teamNames.third-position {
    display: none;
  }
}

@media (max-width: 768px) {
  .top-teamNames {
    text-align: left;
    font-family: "MarkPro Bold";
    /* margin-left: 81px; */
    display: inline-block;
  }
}

.top-scorer-data {
  min-width: 274px;
}

.top-scorer.first-position {
  left: 370px;
  top: -20px;
  z-index: 101;
  width: 250px;
  text-align: center;
  font-size: 60px;
  background: -webkit-linear-gradient(180deg, #000000, #cccccc);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  mix-blend-mode: difference;
}
@media (max-width: 768px) {
  .top-scorer.first-position {
    left: 0;
    width: 100%;
  }
}

.top-scorer.second-position {
  left: 195px;
  top: -20px;
  z-index: 100;
  width: 250px;
  text-align: center;
  font-size: 50px;
  background: -webkit-linear-gradient(180deg, #000000, #cccccc);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  mix-blend-mode: difference;
}
@media (max-width: 768px) {
  .top-scorer.second-position {
    display: none;
  }
}

.top-scorer.third-position {
  left: 150px;
  top: -20px;
  z-index: 99;
  width: 250px;
  text-align: center;
  font-size: 50px;
  background: -webkit-linear-gradient(180deg, #000000, #cccccc);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  mix-blend-mode: difference;
}
@media (max-width: 768px) {
  .top-scorer.third-position {
    left: 310px;
  }
}

@media (min-width: 768px) and (max-width: 970px) {
  .top-scorer-data {
    min-width: 200px;
  }
}

.team-trophy {
  width: 274px;
  min-height: 292px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-inline: auto;
  position: absolute;
}

@media (max-width: 768px) {
  .team-trophy {
    width: 54px;
    height: 58px;
    min-height: auto;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -29px;
  }
}

@media (min-width: 768px) and (max-width: 970px) {
  .team-trophy {
    width: 200px;
    min-height: 262px;
  }
}

@media (min-width: 971px) and (max-width: 991px) {
  .team-trophy {
    min-height: 322px;
  }
}

.team-trophy.first-position {
  width: 1000px;
  height: 800px;
  background-image: url("../images/gold.png");
  background-position: center center;
  left: -320px;
  top: 0;
  z-index: 101;
}
@media (max-width: 768px) {
  .team-trophy.first-position {
    left: 50%;
    width: 100%;
    min-width: 500px;
    height: 700px;
    transform: translateX(-50%);
  }
}

.team-trophy.second-position {
  width: 650px;
  height: 900px;
  background-image: url("../images/silver.png");
  background-position: center center;
  left: -340px;
  top: -30px;
}
@media (max-width: 768px) {
  .team-trophy.second-position {
    display: none;
  }
}

.team-trophy.third-position {
  width: 550px;
  height: 700px;
  background-image: url("../images/bronze.png");
  background-position: center center;
  left: 40px;
  top: 80px;
}
@media (max-width: 768px) {
  .team-trophy.third-position {
    display: none;
  }
}

.trophy-label {
  text-align: center;
  background: yellow;
  position: absolute;
  background: -webkit-linear-gradient(180deg, #000000, #cccccc);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  mix-blend-mode: difference;
}

.trophy-label.first {
  top: 580px;
  font-size: 80px;
  font-family: "MarkPro Black";
  left: 160px;
  z-index: 102;
}

.trophy-label.second {
  top: 570px;
  font-size: 70px;
  font-family: "MarkPro Black";
  left: -35px;
}

.trophy-label.third {
  top: 555px;
  font-size: 60px;
  font-family: "MarkPro Black";
  left: 295px;
}

.top-scorer {
  color: #010737;
  font-family: "MarkPro Bold";
  font-size: 40px;
  text-align: center;
}

.team-mobile-score {
  display: none;
}
@media only screen and (max-width: 768px) {
  .team-mobile-score {
    display: block;
    margin-top: 50px;
  }
  .top-scorer {
    display: none;
  }
  .trophy-label.first {
    top: 435px;
    font-size: 60px;
    font-family: "MarkPro Black";
    left: 190px;
    z-index: 102;
  }
}

.trophies .row {
  margin-top: 50px;
  min-height: 500px;
}

@media only screen and (max-width: 768px) {
  .trophies > div {
    padding-inline: 0;
  }
  .trophies .row > div {
    padding-inline: 0;
  }

  /*.trophies {
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .trophies::-webkit-scrollbar {
    width: 15px;
    height: 15px;
  }

  .trophies::-webkit-scrollbar-track {
    background-color: #cdced2;
  }

  .trophies::-webkit-scrollbar-thumb {
    background-color: #4cafe5;
    outline: 4px solid transparent;
    border-radius: 33px;
    border: 4px solid #cdced2;
  }
  */
}

.teamText {
  display: block;
  height: 220px !important;
  width: 100% !important;
}

/* Leader Board */
.scoreboard-body table {
  border-collapse: separate;
  border-spacing: 0;
  font-family: "MarkPro Bold";
  font-size: 30px;
  background: rgba(0, 0, 0, 0.4);
}

@media only screen and (max-width: 768px) {
  .scoreboard-body table {
    font-size: 24px;
  }
}

.scoreboard-body .w-100 {
  margin-bottom: 0 !important;
}

.scoreboard-body .table-responsive {
  margin-bottom: 10px !important;
}

@media only screen and (max-width: 768px) {
  .scoreboard-body .table-responsive {
    margin-bottom: 180px !important;
  }
}

.scoreboard-body .table-responsive::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}

.scoreboard-body .table-responsive::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: #cdced2;
}

.scoreboard-body .table-responsive::-webkit-scrollbar-thumb {
  background-color: #4cafe5;
  outline: 4px solid transparent;
  border-radius: 33px;
  border: 4px solid #cdced2;
}

.scoreboard-body table thead {
  width: 100%;
  background-color: #e65cae;
  border: 1px solid #e65cae;
  height: 68px;
  color: #fff;
  font-size: 28px;
  border-top: 0 !important;
  position: sticky;
  top: 0;
  z-index: 1;
}

@media only screen and (max-width: 768px) {
  .scoreboard-body table thead {
    font-size: 24px;
  }
}

.scoreboard-body table th,
.scoreboard-body table td {
  font-weight: 100;
  text-align: center;
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0.73);
  border-style: solid;
  border-collapse: separate;
  border-spacing: 1px !important;
  margin: 0 !important;
  border-right: 0;
  border-top-color: #e65cae;
  border-bottom-color: #e65cae;
}

@media only screen and (max-width: 768px) {
  .scoreboard-body table th,
  .scoreboard-body table td {
    border-color: rgba(0, 0, 0, 0.33);
    border-top-color: #010737;
    border-bottom-color: #010737;
  }
}

#leaderBoard-head,
#leaderBoard-head th {
  border-top: 0;
  border-left-color: rgba(255, 255, 255, 0.33);
}

@media only screen and (max-width: 768px) {
  #leaderBoard-head,
  #leaderBoard-head th {
    border-left-color: rgba(0, 0, 0, 0.33);
  }
}

.scoreboard-body table tr td:first-of-type,
#leaderBoard-head th:first-of-type {
  border-left: 0 !important;
}

.scoreboard-body table tr td:last-of-type,
#leaderBoard-head th:last-of-type {
  border-right: 0 !important;
}

.scoreboard-body table tr:last-of-type,
.scoreboard-body table tr:last-of-type td {
  border-bottom: 0 !important;
}

.scoreboard-body table thead th.leader-team-name {
  padding-left: 22px !important;
  font-family: "MarkPro Bold";
}

@media only screen and (max-width: 768px) {
  .scoreboard-body table thead th.leader-team-name {
    font-size: 24px;
  }
}

.scoreboard-body table thead .pp-head {
  background-color: #9100d4;
}

.scoreboard-body table thead .list-head {
  background-color: #4cafe5;
  min-width: 124px;
}

.scoreboard-body table thead .total-head {
  background-color: #4ca63d;
}

/* table thead td:first-child {
    border-radius: 0px;
}

table thead td:last-child {
    border-radius: 0;
} */

#leaderBoard {
  width: 100%;
  position: relative;
  z-index: 103;
}

.scores-area {
  opacity: 0;
  transition-property: opacity;
  transition-duration: 1s;
  transition-timing-function: linear;
}

.scores-area.show-board {
  opacity: 1;
  transition-property: opacity;
  transition-duration: 1s;
  transition-timing-function: linear;
  transition-delay: 5s;
}

.scores-area.show-fast {
  margin-top: 60px;
  opacity: 1;
}

.rank {
  width: 60px;
  min-width: 65px;
  font-family: "MarkPro Bold";
}

.rank i {
  color: #fff;
}

.leaderBoard-list {
  background: rgba(0, 0, 0, 0.7);
  height: 68px;
  color: #fff;
  border: 2px solid #e65cae !important;
}

@media only screen and (max-width: 768px) {
  .leaderBoard-list {
    color: #010737;
    border: 2px solid #010737 !important;
    background: #fff;
  }
}

.total-score {
  background-color: rgba(76, 166, 61, 0.31) !important;
  color: #fff;
  font-weight: 500;
  width: 125px;
  min-width: 75px;
}

.leader-team-name {
  width: 320px;
  min-width: 200px;
  font-family: "MarkPro";
  font-size: 30px;
  border-left: 0px !important;
}

.roundWise-score {
  width: 95px;
  min-width: 75px;
}
.style1 {
  /* style="height: 120% !important; background-color: #FFB610;" */
  background-color: #ffb610;
}
.style2 {
  /* style="height: 120% !important; background-color: #FFB610;" */
  background-color: #a7a7ad;
}
.style3 {
  /* style="height: 120% !important; background-color: #A77044;" */
  background-color: #a77044;
}
.header-radius-right {
  border-radius: 00;
}
.info-txt {
  font-family: "MarkPro";
  font-size: 14px;
  line-height: 35px;
  padding: 16px 12px 0px;
  margin-bottom: 0px;
}
.p-txt {
  font-size: x-large;
}

.fw-20 {
  font-size: 20px !important;
  font-weight: 100 !important;
}

.fw-27 {
  font-size: 27px !important;
  font-weight: 100 !important;
}