@font-face {
  font-family: AlternateGothic;
  src: url("../src/fonts/AlternateGothic.ttf");
}

@font-face {
  font-family: "MarkPro";
  src: url("../src/fonts/MarkPro-Book.ttf");
}

@font-face {
  font-family: "MarkPro Bold";
  src: url("../src/fonts/MarkPro-Bold.ttf");
}

@font-face {
  font-family: "MarkPro Black";
  src: url("../src/fonts/MarkPro-Black.ttf");
}

@font-face {
  font-family: "MarkPro Light";
  src: url("../src/fonts/MarkPro-Light.ttf");
}

body,
#root {
  max-width: 100vw;
  overflow-x: hidden;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
a {
  font-family: "MarkPro" !important;
}

h3.serialNo {
  font-family: "MarkPro Black";
  font-size: 20px;
  margin-top: 10px;
  background: #4cafe5;
  padding: 10px 0 0 0 ;
  border-radius: 25px;
  color: white;
  max-width: 40px;
  width: 40px;
  height: 40px;
  text-align: center;
}

.breadcrumb .active {
  font-family: "MarkPro" !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.bion-btn .btn-danger {
  background-color: #9100d4 !important;
  border-color: #9100d4 !important;
}

.bion-btn .btn-primary {
  background-color: #4cafe5 !important;
  border-color: #4cafe5 !important;
}

.btn-success {
  background-color: #4ca63d;
  border-color: #4ca63d;
  color: #fff;
}

.leaderboard-table thead > tr > th,
.leaderboard-table tbody > tr > td {
  text-align: center;
}

.leaderboard-table thead > tr,
.leaderboard-table tbody > tr {
  border-bottom: 2px solid #f8f9fa !important;
}

.leaderboard-table thead > tr > th {
  background-color: #00a7ea !important;
  font-weight: 600;
  color: #fff;
}

.leaderboard-table tbody > tr > td.team-active {
  font-weight: 600;
}

.leaderboard-table tbody > tr > td.pp-active {
  background-color: #e82e7d73 !important;
  font-weight: 600;
}

.leaderboard-table tbody > tr > td.list-active {
  background-color: #00a7ea73 !important;
  font-weight: 600;
}

.leaderboard-table thead > tr > th.total-active,
.leaderboard-table tbody > tr > td.total-active {
  background-color: #4cafe5 !important;
  font-weight: 600;
}

.question {
  font-family: 500;
}

.empty-number {
  opacity: .1;
}

.jj_active {
  color: #60C7FF !important;
  font-weight: 600;
  opacity: 1;
 
}

.jj_active .empty-number {
  opacity: 1 !important;
  background: transparent url('./content/images/joker.png') center center no-repeat;
  background-size: 30px;
}

#leaderBoard-body.jj_hide .jj_active .empty-number{
  background: none !important;
  color: #999999 !important;
  opacity: .1 !important;
}

.all-scores {
  positon: relative;
}

.all-scores #leaderBoard-body tr, .all-scores thead {
  height: 41.5px !important;
}

.all-scores #leaderBoard-body td, .all-scores  th {
  font-size: 26px !important;
  padding:0  !important;
  margin: 0  !important;
  height: 20px !important;
  line-height: 26px !important;
}

.all-scores.scoreboard-body {
  padding: 0 0 0 140px !important;
}
.all-scores .scores-area {
  margin-top: 0px !important;
}

.all-scores .leaderBoard-footer {
  display: none !important;
}

.all-scores .jj_active .empty-number {
  background-size: 20px;
}

.allscore-buttons {
  display: none;
}

.all-scores .allscore-buttons {
  display: block;
}

.all-scores .allscore-buttons .top-button-small {
  position: fixed;
  top:82vh;;
  left:40px;
  z-index: 200;
  width:60px;
  height:60px;
}

.all-scores .allscore-buttons .bottom-button-small {
  position: fixed;
  top: 90vh !important;
  left:40px;
  z-index: 200;
  width:60px;
  height:60px;
}
.all-scores .allscore-buttons .logos {
  position: fixed;
  top: 45px;
  left: 20px;
  width: 100px;
  height: auto;
  cursor: pointer;
}

.leaderBoard-footer .logos {
  cursor: pointer;
}

.gold {
  background-color: #ffb610 !important;
}

.silver {
  background-color: #a7a7ad !important;
}

.bronze {
  background-color: #a77044 !important;
}

.leaderboard-panel {
  height: 40vh !important;
}

.leaderboard-panel .card {
  text-align: left !important;
}

.leaderboard-panel .card p {
  font-weight: 600;
  font-size: 1rem;
  font-style: italic;
}

.label .input-error,
select.input-error {
  border-color: red;
}

.error {
  padding: 3px 0 0 3px;
  font-size: 12px;
}

.bg-salmon {
  background-color: green;
  color: white;
}

.bion-btn .btn {
  width: 48px !important;
  max-width: 48px !important;
  height: 48px !important;
  font-size: 16px;
  line-height: 40px;
  color: #000;
  padding-left: 0px;
  padding-right: 0px;
  margin: 10px !important;
  flex: 1 0 100% !important;
}

.bion-btn .btn.disabled {
  color: #000;
  border-color: #cccccc;
  opacity: 1;
}

.busterCards button {
  width: 48px !important;
  height: 48px;
  border-radius: 3px;

  padding: 0px !important;
  margin: 8px 4px !important;
}

.busterCards button.btn-success,
.bion-btn button.btn-success,
.bion-btn button.btn-danger {
  color: #fff;
}

.busterCards button.disabled {
  border: 1px solid #cccccc;
  border-radius: 3px;
  opacity: 1;
}

.btn.rounded-0 {
  border-radius: 3px !important;
}

.btn-container-right {
  text-align: right !important;
}

.bion-btn-disabled {
  /* border: 1px solid #f1f1f1 !important; */
  color: #ced4da !important;
  border: none !important;
  opacity: 1;
}

.td-bold {
  font-weight: 600 !important;
  text-align: left;
}

.modal-close-btn {
  text-align: right;
}

.team-reg-modal-icon {
  font-size: 20px;
  color: #4ca63d;
}

.quit-modal {
  font-size: 40px;
  color: #00a7ea;
}

.table-dashboard {
  background-color: #00a7ea;
}

.table-dashboard-row {
  background-color: #03ac5d;
}

.data-failure-modal {
  font-size: 20px;
  color: red;
}

.scorecard-display-btn-pp {
  background-color: #9100d4;
  border-color: #9100d4;
  color: #fff;
}

.scorecard-display-btn-primary {
  background-color: #4cafe5;
  border-color: #4cafe5 !important;
  color: #fff;
}

.scorecard-display-btn-success {
  background-color: #4ca63d;
  border-color: #4ca63d;
  color: #fff;
}

.gap {
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.display-none {
  display: none;
}

.sidebar-nav li.nav-item {
  cursor: pointer;
}

.rounded-5 {
  border-radius: 5px !important;
  width: 2rem;
}

.team-red-modal-icon {
  font-size: 40px;
  color: #ed3838;
}

.btn-fixed {
  min-width: 84px;
  min-height: 36px;
}

.td-right {
  text-align: right;
}

.accordion-button::after {
  filter: brightness(100%) invert(0%);
}

@media only screen and (max-width: 991px) {
  .team-total-score {
    text-align: center !important;
  }
}

/* .fw-20{
  font-size: 20px !important;
  font-weight: 100 !important;
} */

.leader-team-name-width {
  width: 320px;
}

.breadcrumb li.breadcrumb-item {
  font-weight: 100 !important;
}

/* Create-new-questions */
.radio-btn-create-question,
.radio-btn-create-question div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.tags-input-container {
  border: 1px solid #cdc3c3b8;
  padding: 0.375rem 0.75rem;
  /* padding: 0 0.75rem; */
  border-radius: 1px;
  /* margin-top: 1rem; */
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
}

.tags-input-container-search {
  border: 1px solid #cdc3c3b8;
  padding: 0.25rem;
  border-radius: 0px;
  /* margin-top: 1rem; */
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
}

.tag-item {
  align-items: center;
  background-color: rgb(218, 216, 216);
  display: inline-block;
  padding: 0.25rem 0.75rem;
  /* border-radius: 20px; */
  display: flex;
}

.tag-item-search {
  align-items: center;
  background-color: rgb(218, 216, 216);
  display: inline-block;
  padding: 0.25rem 0.75rem;
  /* border-radius: 20px; */
  display: flex;
  /* padding-left: 9px; */
  /* margin-left: 4px; */
  margin: 1px;
}

.tag-item .close {
  margin-left: 0.5rem;
}

.tag-item-search .close {
  height: 16px;
  width: 16px;
  background-color: rgb(48, 48, 48);
  display: inline-block;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
  font-size: 18px;
}

.keyword-search-ques {
  margin-left: 0.5rem;
}

.tags-input {
  flex-grow: 1;
  border: none;
  outline: none;
}

@media (max-width: 500px) {
.w-50 {  
    width: 80% !important;
  }
}

.tags-input-search {
  flex-grow: 1;
  border: none;
  outline: none;
  /* padding: .375rem .75rem; */
}

.rdw-editor-main {
  margin-left: 6px;
  height: 150px !important;
  max-height: 250px !important;
  overflow: auto;
  scroll-behavior: auto;
}

.public-DraftStyleDefault-ltr {
  margin: 1px !important;
}

.suggestions {
  cursor: pointer;
}

.suggestions:hover {
  background-color: gray;
}

.i-box {
  height: 38px;
}

.i-box-keyword {
  height: 32px;
}

.res-border {
  /* height: 200px; */
  /* max-height: 150px; */
  border: 1px solid #aaadb4;
  overflow-y: auto;
}

table.question-result {
  border-collapse: collapse;
}

table.question-result tbody tr {
  border-bottom: 1px solid #c6c9cc;
}

table.question-result tbody tr td:first-child {
  text-align: start;
}

table.question-result thead {
  height: 1.8rem !important;
  background-color: #0babeb !important;
  top: 0;
  position: sticky;
}

/* question search css */

.gap {
  gap: 1rem;
}

.form-check-input[type="checkbox"] {
  border-radius: 0;
}

.form-check-input:checked[type="radio"] {
  background-color: #198754;
  border-color: #198754;
}

.selected {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.res-border {
  border: 1px solid #ced4da;
  overflow: auto;
  min-height: 130px;
  max-height: 300px;
}

/* table.question-result{
    --bs-table-hover-bg: #f6f9ff !important;
} */
.col-form-label {
  font-weight: inherit;
}

@media (max-width: 500px) {
.text-end {
    margin-top: 1rem;
  }
}

.labels {
  background-color: #f6f9ff;
  border: 1px solid #00a7ea;
  color: #00a7ea;
}

.questionHeader {
  font-weight: bolder;
}

.question-label {
  display: unset !important;
}

.form-check-input-question {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  /* -webkit-appearance: none; */
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  print-color-adjust: exact;
}

.option-box {
  padding-right: 10px !important;
  padding-left: 0px !important;
}

.py--cross-3 {
  padding-top: 1rem !important;
  /* padding-bottom: 1rem!important; */
}

.table-border-search {
  border: 0px !important;
}

input[type="checkbox"] {
  border-radius: 0 !important;
}

.clueInfo .rdw-editor-toolbar {
  box-sizing: content-box;
  height: 60px !important;
  max-height: 100px !important;
  overflow: auto;
  scroll-behavior: auto;
}
.hostInfo .rdw-editor-toolbar {
  box-sizing: content-box;
  height: 60px !important;
  max-height: 100px !important;
  overflow: auto;
  scroll-behavior: auto;
}

.ant-table-wrapper {
  overflow-x: scroll;
}

.ant-table-wrapper::-webkit-scrollbar {
   width: 15px;
  height: 15px;
}

.ant-table-wrapper::-webkit-scrollbar-track {
  background-color: #cdced2;
}

.ant-table-wrapper::-webkit-scrollbar-thumb {
  background-color: #4cafe5;
  outline: 4px solid transparent;
  border-radius: 33px;
  border: 4px solid #cdced2;
}

.ant-spin-container {
  min-width: 768px;
}

.ant-table-thead {
  background-color: #0babeb !important;
  top: 0;
  position: sticky;
}

.ant-table-container {
  /* border-top-left-radius: 2px; */
  /* border-top-right-radius: 2px; */
  border: 1px solid #ced4da;
  /* min-height: 130px; */
  /* max-height: 300px; */
}

.ant-table-thead > tr > th {
  position: relative;
  color: #f2f2f2 !important;
  /* font-weight: 600 !important; */
  text-align: left;
  background: #16aeec !important;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s ease;
  font-size: medium !important;
  text-align: center !important;
}

.col-form-label {
  /* font-weight: 600 !important; */
  font-size: medium !important;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #e02a84 !important;
}

.ant-table-column-sorter {
  margin-left: 4px;
  color: #ffffff !important;
  font-size: 0;
  transition: color 0.3s;
}

.ant-table-tbody > tr > td {
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s;
  text-align: center !important;
}

.ant-table-cell-scrollbar:not([rowspan]) {
  box-shadow: 0 0 0 0 #fafafa !important;
}

div.form-control.standard-option p {
  margin: 0 !important;
}

/* table thead.ant-table-thead{
  padding: 8px !important;
  height: 48px !important;
}
table thead.ant-table-thead th, table tbody.ant-table-tbody td{
  padding: 8px !important;
} */

/* body {
  background-color: #f6f9ff !important;
} */
.trash-hover {
  color: #b3afaf;
}
.trash-hover:hover {
  color: red;
}

table {
  border-collapse: unset !important;
}
.info-search-modal-icon {
  font-size: 40px;
  color: #3ab5ee;
}

/* input[type="file"]::-webkit-file-upload-button{
  float: right;
  margin: 0 0 0 10px;
} */

i.fa-star-of-life {
  font-size: 8px;
  vertical-align: top;
  padding-top: 8px;
}
.uploadFile {
  font-size: medium !important;
}

.lock-icon {
  font-size: 18px !important;
}

.p-txt-initial {
  font-size: 20px;
}

.lock-img {
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.lock-img i {
  width: 30px !important;
  font-size: 12px !important;
}

.lock-flex {
  display: flex;
}
/* 
.game-actionButton .btn-size {
  height: 37px!important;
    width: 78px!important;
} */

/* toopltip */

.tooltipQuizes .tooltiptext,
.tooltipp .tooltiptext {
  visibility: hidden;
  width: 80px;
  background-color: #fff;
  color: #212529;
  text-align: center;
  border: 1px solid #212529;
  /* padding: 5px 0; */
  position: absolute;
  z-index: 1;
  bottom: -15%;
  left: 10%;
  margin-left: -136px;
  opacity: 0;
  transition: opacity 0.3s;
  height: 24px;
}

/* .tooltipp .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
} */

.tooltipp:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.bion-btn .btn.text-score {
  position: relative;
  max-width: 44.1% !important;
  border: 1px solid #cccccc !important;
  line-height: 20px !important;
  font-size: 12px !important;
}

.bion-btn .btn.text-score.btn-warning {
  position: relative;
  max-width: 105% !important;
  width: 110% !important;
  line-height: 20px !important;
  font-size: 12px !important;
  font-family: "MarkPro Black" !important;
  margin: -2px !important;
}

.bion-btn .btn.text-score span {
  position: absolute;
  left: 5px;
  top: 5px;
  font-family: "MarkPro Black";
}

@media only screen and (min-width: 600px) {
  .bion-btn .btn.text-score {
    max-width: 29.5% !important;
  }
}

@media only screen and (min-width: 1024px) {
  .bion-btn .btn.text-score {
    max-width: 20% !important;
  }
}

.table-row {
  border-bottom: 1px solid #999999 !important;
  margin-bottom: 20px !important;
  padding-bottom: 20px !important;
}

.btn-rounded {
  border-radius:30px;
}

.btn.wider {
  min-width: 120px !important;
  padding: 8px 0px 8px 0px;
  display: inline-block;
}

.btn .inner-button {
  background: transparent;
  display: inline-block;
  border-radius: 20px;
  border: 1px solid white;
  margin: 0 5px;
  padding: 4px 8px;
}

.push-up {
  top: -24px;
  max-width: 140px;
}
.push-up span {
  font-size: 12px !important;
}